import { VisuallyHidden } from '@overdose/components'
import { IconShoppingBag } from '@tabler/icons-react'
import { Button } from '~/components/Button/'
import { AddToCartButtonProps } from '~/components/ProductCard/ProductCard.types'
import { useCart, useCartDrawer } from '~/hooks'
import { useAlgoliaSearchInsignts } from '~/hooks/useAlgoliaSearchInsignts'
import { GTM, GTMEvent } from '~/lib'

export const AddToCartButton = ({
  sku,
  inStock,
  titleSection,
  queryId,
  externalUrl,
}: AddToCartButtonProps) => {
  const { addToCart } = useCart()
  const { openCartDrawer } = useCartDrawer()
  const { sendAddToCartEvent } = useAlgoliaSearchInsignts()

  const handleClicked = () => {
    if (externalUrl) {
      return
    }

    addToCart.mutate([{ sku, quantity: 1, itemContainer: titleSection }], {
      onSuccess(data) {
        if (data) {
          GTM.dispatch(GTMEvent.CLEAR_ECOMMERCE)
          GTM.dispatch(GTMEvent.CART_CONTENTS_PUSH, data)
          sendAddToCartEvent(data.lineItems, data.subTotal, queryId)
        }
        openCartDrawer()
      },
    })
  }

  return (
    <Button
      loading={addToCart.isLoading}
      data-id='addToCartButton'
      size='sm'
      icon={<IconShoppingBag />}
      iconOnly
      shape='circle'
      href={externalUrl}
      onClick={handleClicked}
      data-testid='add-to-cart-button'
      disabled={!inStock}
      className='overflow-hidden'>
      <VisuallyHidden>
        {externalUrl ? 'Buy Online' : 'Add to cart'}
      </VisuallyHidden>
    </Button>
  )
}
